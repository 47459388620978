body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.edit-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #eeaa11;
}

.gradient-background {
  background-image: linear-gradient(
    180deg,
    rgb(0, 125, 254) 0%,
    rgb(4, 23, 88) 100%
  );
  min-height: 92vh;
}

.performance-card {
  width: 30%;
}

/* Your global styles */

/* Media query for smaller screens (mobile devices) */
@media (max-width: 768px) {
  .stories-container {
    width: 100%;
    height: 100vh;
    /* Adjust the height based on your design preferences */
  }
  .performance-card {
    width: 100% !important;
  }
}

/* Media query for larger screens (web) */
@media (min-width: 769px) {
  .stories-container {
    max-width: 432px;
    /* Adjust the maximum width for larger screens */
    width: 100%;
    height: 768px;
    /* Adjust the height based on your design preferences */
    margin: 0 auto;
    /* Center the container horizontally */
  }
}

/* Your global styles */

/* Styles for the home button */
.home-button {
  position: absolute;
  cursor: pointer;
}

.home-button button {
  padding: 8px 16px;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  /* Add more common styles */
}

/* Media query for smaller screens (mobile devices) */
@media (max-width: 768px) {
  .home-button {
    top: 20px; /* Adjust the top position for mobile */
    right: 20px; /* Adjust the right position for mobile */
  }

  .home-button button {
    /* Adjust button styles for mobile */
    font-size: 14px;
    padding: 6px 12px;
  }
}

/* Media query for larger screens (web) */
@media (min-width: 769px) {
  .home-button {
    top: 40px; /* Adjust the top position for larger screens */
    right: 40px; /* Adjust the right position for larger screens */
  }

  .home-button button {
    /* Adjust button styles for larger screens */
    font-size: 16px;
    padding: 8px 16px;
  }
}
