input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.deactivatebtnhover:hover {
  background-color: red !important;
  color: white !important;
}

.custom-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  /* Updated: semi-transparent light overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it's above other content */
}

.custom-modal-content {
  padding: 20px;
  border-radius: 8px;
}

a {
  text-decoration: none !important;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  border: 0;
  outline: 0;
}

input:focus {
  outline: none !important;
}

.bgimg {
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-size: cover;
}

input[type='date']:before {
  content: attr(placeholder) !important;
  color: grey;
}

input[type='date']:focus:before,
input[type='date']:valid:before {
  content: '';
}

i {
  cursor: pointer;
}

.formbg {
  background-color: #0d94fb !important;
  border-width: 2px;
  border-color: #02042b;
  padding: 20px;
}

.formbg .form-control-label {
  text-align: left;
}

.website-btn {
  color: white;
}

.website-btn:hover {
  color: #0d94fb;
}

.inblue {
  color: #0d94fb;
}

.indark {
  color: #02042b;
}

.website-btn1 {
  color: black;
  background-color: white;
}

.website-btn1:hover {
  background-color: #0d94fb;
  color: white;
}

.newbtn {
  color: #0d94fb;
  border: #0d94fb solid 1px;
  background-color: white;
  width: 7em;
  height: 1.8em;
  vertical-align: middle;
  padding: 0px 15px 0px 15px;
}

.newbtn1 {
  width: 8em;
  height: 2em;
  vertical-align: middle;
  padding: 0px 15px 0px 15px;
}

.newbtn:hover {
  color: white;
  border: #0d94fb solid 1px;
  background-color: #02042b;
}

.btn-hover {
  color: white !important;
}

.btn-hover:hover {
  color: #0d94fb !important;
}

.btn-hover-1 {
  color: black;
}

.btn-hover-1:hover {
  color: #0d94fb;
}

.files input {
  outline: 3px dashed #0d94fb;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  /* padding: 100px; */
  border-radius: 25px;
  text-align: center !important;
  /* margin: 0; */
  width: 100% !important;
}

.h-100 {
  height: 0% !important;
}

.homeh1 {
  font-size: 2.3em;
}

.homeimg {
  width: 40%;
  margin-top: 5%;
}

.limg {
  width: 16%;
}

.performance-container {
  /* height: 100px; */
  background-color: white;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media only screen and (max-width: 768px) {
  .limg {
    width: 70%;
  }

  .homeh1 {
    font-size: 1.5em;
  }

  .homeimg {
    width: 70%;
    margin-top: 5%;
  }

  .mfont {
    font-size: 0.7em !important;
  }

  .mfont1 {
    font-size: 0.9em !important;
  }

  .borderFront {
    border-right: none !important;
  }

  .youtube-video iframe {
    width: 100% !important;
  }

  .telegramIcon {
    width: 75px !important;
  }

  .telegramText {
    font-size: 0.9rem !important;
  }

  .main-container {
    height: 80% !important;
  }
}

.borderFront {
  border-right: 2px solid #0d94fb;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.one,
.two,
.three {
  position: absolute;
  margin-top: -10px;
  z-index: 1;
  height: 40px;
  width: 40px;
  border-radius: 25px;
}

.one {
  left: 25%;
}

.two {
  left: 50%;
}

.three {
  left: 75%;
}

.success-color {
  background-color: #5cb85c;
}

.no-color {
  background-color: inherit;
}

.sideBar-card {
  border: 1px solid blue;
}

.button-stock {
  border-radius: 10px;
  width: 20%;
  margin-right: 20px;
}

.stock-card {
  /* border: 1px solid; */
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.expand-card {
  border-left: 4px solid #02042b;
}

.noexpand-card {
  border-left: 2px solid grey;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-ht {
  height: 2.2em;
  vertical-align: middle;
  padding: 0px 15px 0px 15px;
}

.btn-ht:hover {
  border: #02042b 2px solid !important;
}

.int-fs-10 {
  font-size: 0.625em;
}

.int-fs-12 {
  font-size: 0.75em;
}

.int-fs-14 {
  font-size: 0.875em;
}

.int-fs-16 {
  font-size: 1em;
}

.int-fs-17 {
  font-size: 1.063em;
}

.int-fs-20 {
  font-size: 1.25em;
}

.int-fs-25 {
  font-size: 1.563em;
}

.int-fs-28 {
  font-size: 1.75em;
}

.int-text {
  color: #0d94fb;
}

.int-text-blue {
  color: #0f157d;
}

.int-text-green {
  color: #0f9d58;
}

.int-text-red {
  color: #d22828;
}

.int-text-lightgray {
  color: #9c9c9c;
}

.int-text-gray {
  color: #6b6b6b;
}

.int-text-darkgray {
  color: #3a3a3a;
}

.int-text-darkblue {
  color: #356cae !important;
}

.int-text-dark {
  color: #02042b;
}

.int-text-yellow {
  color: #cca003;
}

.int-bg {
  background-color: #0d94fb;
}

.int-bg-yellow {
  background-color: #ffc700;
}

.int-bg-light {
  background-color: #eef7fd;
}

.int-bg-lightgreen {
  background-color: #0f9d5840;
}

.int-bg-lightgray {
  background-color: #d9d9d9;
}

.int-bg-dark {
  background-color: #02042b !important;
}

.int-bg-darkblue {
  background-color: #09306d !important;
}

.int-bg-green {
  background-color: #0f9d58 !important;
}

.int-bg-red {
  background-color: #d22828;
}

.int-border {
  border: #0d94fb 2px solid;
}

.int-grad {
  background: linear-gradient(
    114.25deg,
    #02042b 34.47%,
    rgba(2, 4, 43, 0) 140.59%
  );
}

.int-border-red {
  border-top: red 4px solid;
}

.int-border-blue {
  border-top: #0d94fb 3px solid;
}

.int-border-blue-1 {
  border-top: #0d94fb 4px solid;
  border-bottom: #0d94fb 1px solid;
  border-left: #0d94fb 1px solid;
  border-right: #0d94fb 1px solid;
}

.int-border-org {
  border-top: #f16522 4px solid;
}

.int-border-grey {
  border-top: grey 4px solid;
}

input[type='date']:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type='date']:focus::-webkit-datetime-edit {
  color: black !important;
}

.floating-label-group {
  position: relative;
  margin-top: 15px;
  margin-bottom: 25px;
}

.floating-label-group .floating-label {
  font-size: 13px;
  color: #404040;
  position: absolute;
  pointer-events: none;
  top: 9px;
  left: 12px;
  transition: all 0.1s ease;
}

.floating-label-group input:focus ~ .floating-label,
.floating-label-group input:not(:focus):valid ~ .floating-label {
  top: -20px;
  bottom: 0px;
  left: 0px;
  font-size: 11px;
  opacity: 1;
  color: #0d94fb;
}

.left-sidebar {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  z-index: 1;
  padding-bottom: 5%;
}

.center-container {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  z-index: 1;
  margin-left: 16.5%;
  padding-bottom: 5%;
}

.main-container {
  height: 80%;
  position: fixed;
  overflow-y: scroll;
  z-index: 1;
  width: 100%;
  padding-bottom: 7%;
}

.right-sidebar {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  z-index: 1;
  right: 0;
}

.Rec {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
  }

  65% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
  }

  90% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
  }
}

.blur-text {
  color: transparent;
  text-shadow: 0 0 8px #000;
}

.leaves-image {
  background-image: url(../public/images/patte.webp);
  background-repeat: no-repeat;

  /* Set rules to fill background */
  min-height: 100%;
  min-width: 100px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

.inverted-commas {
  background-image: url(../public/images/inverted-commas.webp);
  background-repeat: no-repeat;
}

.modal-transparent {
  --bs-modal-bg: transparent !important;
}

tr.call:hover {
  border-color: #007bff !important;
}

@media only screen and (max-width: 768px) {
  .choose-banner {
    width: 100% !important;
  }
}

.choose-banner {
  width: 75%;
  text-align: center;
}
.panel {
  padding: 0 18px;
  background-color: #356cae;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.validity-div .label {
  color: #5a5a5a;
}

.validity-div .validity {
  color: #3a3a3a;
}

.price {
  color: #3a3a3a;
  font-weight: 500;
  font-size: 17px;
}

.card-shadow {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0px !important;
}

/* Default styles for larger screens */
.tab-custome-style {
  overflow-x: auto;
  overflow-y: hidden;
}

@media (max-width: 366px) {
  .tab-text {
    font-size: 12.5px;
  }
}

/* Media query for screens up to 991.98px (mobile screens) */
@media (max-width: 991.98px) {
  .tab-custome-style {
    width: 85%;
  }
}

.side-panel {
  position: fixed;
  left: -300px;
  /* Start off-screen */
  top: 0;
  height: 100%;
  width: 300px;
  background-color: #f8f9fa;
  /* Light grey background */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease;
  /* Smooth slide in */
  z-index: 1050;
  overflow-y: auto;
  /* Scroll if content overflows */
  padding: 1rem;
}

.side-panel.open {
  left: 0;
  /* Slide in */
}

.side-panel-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.side-panel-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* Space between buttons */
}

.tab-button {
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
  /* Light border for separation */
  border-radius: 5px;
  background-color: #f8f9fa;
  /* White background */
  transition: background-color 0.2s;
}

.tab-button:hover,
.tab-button.active {
  background-color: #cfeafe;
  /* Light blue background on hover or active */
  border-color: #86c8f2;
  /* Slightly darker border */
}

.tabs-button-container {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 10;
}

.tabs-button {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.table {
  font-size: 0.9rem; /* Adjusts the overall font size */
}

.table th,
.table td {
  padding: 0.5rem; /* Reduces padding for a more compact table */
}

.table th {
  background-color: #f8f9fa; /* Light background for header */
}

@media (max-width: 576px) {
  .table {
    font-size: 0.8rem; /* Even smaller font size for very small screens */
  }
}
