.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34 !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-custom {
  color: white;
}

.btn-custom:hover {
  color: #0d94fb !important;
}

.int-text {
  color: #0d94fb !important;
}

.int-bg {
  background-color: #0d94fb !important;
}

.int-border {
  border: #0d94fb 2px solid !important;
}

.chevron {
  border-radius: 0px 0px 0px 250px;
}

.face:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.service-card {
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #798795;
  margin: 0px;
  font-family: 'Nunito', sans-serif;
}

.title {
  font-weight: bold;
}

.service-card {
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.service-card p {
  color: #2783f3 !important;
  margin: 15px;
  padding: 10px;
}

.service-card-new {
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  padding: 20px !important;
}

.service-offer {
  background-color: #2783e3 !important;
  color: white !important;
  border-radius: 50px;
  padding-right: 10px;
  padding-left: 10px;
}

.new-card h1 {
  font-size: 4em;
  font-weight: bold;
}

.sub-header {
  background-color: #02042b;
  color: white;
  border-top: 2px solid white;
  padding-top: 10px !important;
}

.offer-container {
  margin-top: 30px;
}

.offer-img img {
  padding-top: 10px !important;
}

.subscribe-btn {
  border-radius: 20px;
  color: #fff;
  margin-top: 10px;
  background-color: #004597 !important;
}

.Performance-btn {
  border-radius: 20px;
  color: #fff;
  margin-top: 10px;
  background-color: #02042b;
}

.past_performance_container {
  background-color: #02042b;
  display: inline-block;
  padding: 20px 15px;
  border-radius: 10px;
}

.past_performance_desktop_container {
  background-color: #02042b;
  display: inline-block;
  padding: 20px 15px;
  border-radius: 10px;
}

.what-will-you-get {
  padding: 20px 15px;
  border-radius: 10px;
}

.what-will-you-get-card {
  background-color: #02042b;
  color: #fff;
  padding: 20px 15px;
  border-radius: 10px;
  width: 24%;
}

.price-list {
  list-style: none;
  padding-left: 0 !important;
}

.price-list li:before {
  content: '✓';
  padding-right: 10px;
}

.buy-button {
  width: 100%;
  height: 35%;
  background: linear-gradient(
    87.68deg,
    #ae8625 21.51%,
    #ae8625 21.52%,
    #e0aa3e 52.09%,
    #d2ac47 75.79%,
    #edc967 94.9%
  );
  border-radius: 3px;
  margin-top: 10px;
}

.learn-button {
  width: 100%;
  height: 35%;
  background: #2783f3;
  border-radius: 3px;
  margin-top: 15px;
  color: white;
}

.bottom-card {
  width: 100%;
  background-color: #02042b;
  padding: 20px 20px 30px 20px;
}

.contact-card {
  width: 100%;
  background-color: #2273da;
  padding: 40px;
}
.contact-btn {
  background-color: #02042b;
  color: white;
}

.or-text {
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  background-color: white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: -12px;
}

.price-card {
  width: 100%;
  background-color: #02042b;
  padding: 20px;
  border-radius: 10px;
}

.carousel-item {
  width: 80%;
  margin: 0 10%;
  padding-top: 15px;
}

@media only screen and (max-width: 768px) {
  .nse-container-desktop-view {
    display: none;
  }
  .past_performance_desktop_container {
    display: none;
  }
  .price-container {
    margin: auto;
    width: 100%;
    padding: 10px;
  }
  .what-will-you-get-card {
    /* background-color: #02042B; */
    padding: 20px 15px;
    border-radius: 10px;
    /* color: #fff; */
    width: 48%;
  }
  .profits-desktop-view {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  .nse-container-mob-view {
    display: none;
  }
  .past_performance_container {
    display: none;
  }
  .price-container {
    margin: auto;
    width: 40%;
    padding: 10px;
  }

  .price-card {
    width: 30%;
    margin-top: 30px !important;
    margin: auto;
  }

  .profits-mob-view {
    display: none;
  }
}

.about-title .welcome {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #f14836;
  font-family: 'Nunito', sans-serif;
}
@media (max-width: 767px) {
  .about-title .welcome {
    font-size: 16px;
  }
}
.about-title .title {
  padding-top: 10px;
  font-weight: 400;
  font-size: 32px;
}
@media (max-width: 767px) {
  .about-title .title {
    font-size: 24px;
  }
}
.about-title .title span {
  font-weight: 700;
}

.single-counter .counter-shape {
  width: 55px;
}
.single-counter .counter-shape .shape-1,
.single-counter .counter-shape .shape-2 {
  width: 14px;
  height: 70px;
  position: relative;
  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  transform: rotate(25deg);
  left: 12px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-counter .counter-shape .shape-1,
  .single-counter .counter-shape .shape-2 {
    width: 10px;
  }
}
.single-counter .counter-shape .shape-1::before,
.single-counter .counter-shape .shape-1::after,
.single-counter .counter-shape .shape-2::before,
.single-counter .counter-shape .shape-2::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  top: -1px;
  right: -1px;
  border-top: 12px solid #fff;
  border-left: 16px solid transparent;
}
.single-counter .counter-shape .shape-1::after,
.single-counter .counter-shape .shape-2::after {
  border-top: 0;
  border-bottom: 6px solid #fff;
  top: auto;
  bottom: -1px;
}
.single-counter .counter-shape .shape-1 {
  opacity: 0.5;
  left: 15px;
  bottom: 1px;
}
.single-counter .counter-content {
  padding-left: 10px;
}
.single-counter .counter-content .counter-count {
  font-size: 48px;
  font-weight: 700;
  line-height: 50px;
  color: #222;
  font-family: 'Nunito', sans-serif;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-counter .counter-content .counter-count {
    font-size: 32px;
  }
}
.single-counter .counter-content .text {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
}
.single-counter.counter-color-1 .counter-shape .shape-1,
.single-counter.counter-color-1 .counter-shape .shape-2 {
  background: -webkit-linear-gradient(left, #1cbe99 0%, #05de7d 100%);
  background: -o-linear-gradient(left, #1cbe99 0%, #05de7d 100%);
  background: linear-gradient(to right, #1cbe99 0%, #05de7d 100%);
}
.single-counter.counter-color-2 .counter-shape .shape-1,
.single-counter.counter-color-2 .counter-shape .shape-2 {
  background: -webkit-linear-gradient(left, #3e2cea 0%, #4bb3f9 100%);
  background: -o-linear-gradient(left, #3e2cea 0%, #4bb3f9 100%);
  background: linear-gradient(to right, #3e2cea 0%, #4bb3f9 100%);
}
.single-counter.counter-color-3 .counter-shape .shape-1,
.single-counter.counter-color-3 .counter-shape .shape-2 {
  background: -webkit-linear-gradient(left, #f14836 0%, #ff9387 100%);
  background: -o-linear-gradient(left, #f14836 0%, #ff9387 100%);
  background: linear-gradient(to right, #f14836 0%, #ff9387 100%);
}
.single-counter.counter-color-4 .counter-shape .shape-1,
.single-counter.counter-color-4 .counter-shape .shape-2 {
  background-color: #f23559;
}
.single-counter.counter-color-5 .counter-shape .shape-1,
.single-counter.counter-color-5 .counter-shape .shape-2 {
  background-color: #164aa9;
}

/*===== ABOUT 2 =====*/
.about-content-2 .about-line {
  padding-top: 10px;
}
.about-content-2 .about-line li {
  height: 5px;
  background-color: #fde4e1;
  display: inline-block;
  border-radius: 50px;
  margin-right: 3px;
}
.about-content-2 .about-line li:nth-of-type(1) {
  width: 40px;
}
.about-content-2 .about-line li:nth-of-type(2) {
  width: 15px;
}
.about-content-2 .about-line li:nth-of-type(3) {
  width: 10px;
}
.about-content-2 .about-line li:nth-of-type(4) {
  width: 5px;
}
.about-content-2 .text {
  padding-top: 15px;
}
